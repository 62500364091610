import styled, { css, keyframes } from 'styled-components'

const pulseAnim = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const rollAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const rockAnim = keyframes`
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
`

const rocksAnim = keyframes`
  0% {
    background-position-x: 100%;
  }
  100% {
    background-position-x: 0%;
  }
`

const jumpAnim = keyframes`
  0% {
    bottom: 2px;
  }
  50% {
    bottom: 50px;
  }
  100% {
    bottom: 2px;
  }
`

type StyledProps = {
  isJumping: boolean;
  gameOver: boolean;
}

const StyledGame = styled.div<StyledProps>`
  width: 500px;
  height: 80px;
  background: white;
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  .game-over-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-items: center;
    grid-gap: 4px;
    user-select: none;

    span {
      position: relative;
      top: 1px;
      letter-spacing: 1px;
      pointer-events: none;
    }

    .restart-btn {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border-radius: 6px;
      justify-content: center;
      font-size: 14px;
      letter-spacing: 0.08rem;
      margin-left: 8px;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.gray[700]};
      background-color: ${({ theme }) => theme.color.gray[200]};
      font-family: ${({ theme }) => theme.type.cascadia};

      svg {
        height: 18px;
        width: 18px;
        margin-right: 6px;

        path {
          stroke-width: 2.2px;
        }
      }

      &:hover {
        background-color: ${({ theme }) => theme.color.gray[300]};
      }
    }
  }

  .player {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 4px;
    left: 6px;
    z-index: 10;
    background-color: ${({ theme }) => theme.color.background};
    border-radius: 50%;
    animation: infinite 0.4s ${rollAnim} linear;

    svg {
      height: 24px;
    }

    ${({ isJumping }) => isJumping && css`
      animation: infinite 0.4s ${rollAnim} linear, 0.4s ${jumpAnim} ease-in-out;
    `}

    ${({ gameOver }) => gameOver && `
      animation-play-state: paused;
    `}
  }

  .score {
    position: absolute;
    top: 12px;
    right: 12px;
    pointer-events: none;
    text-align: right;
    display: flex;
    user-select: none;
    font-size: 14px;
  }
  
  .controls {
    position: absolute;
    top: 12px;
    left: 12px;
    pointer-events: none;
    text-align: right;
    display: flex;
    user-select: none;
    font-size: 14px;
    animation: infinite 1.2s ${pulseAnim} ease-in-out;
  }

  .rocks {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background: url(https://media.istockphoto.com/vectors/pixel-pattern-vector-seamless-background-abstrac-glitch-texture-vector-id1188720725?b=1&k=20&m=1188720725&s=612x612&w=0&h=8BeZdd8Ukn4GCArzuLMlbnmBG4KPqfLBN8pseUjkXok=);
    bottom: 0;
    height: 11px;
    background-size: 100px;
    background-position-y: 50px;
    opacity: 0.5;
    z-index: 2;
    animation: infinite 1.2s ${rocksAnim} linear;

    ${({ gameOver }) => gameOver && `
      animation-play-state: paused;
    `}
  }

  .rock {
    position: absolute;
    left: 80%;
    height: 10px;
    width: 10px;
    bottom: 8px;
    transform: rotate(45deg);
    animation: infinite 1.5s ${rockAnim} linear;
    background-color: ${({ theme }) => theme.color.gray[500]};

    ${({ gameOver }) => gameOver && `
      animation-play-state: paused;
    `}
  }

  .base {
    height: 12px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.background};
  }
`

export default StyledGame
