import rawAvatarAvif from './avatar.avif'
import rawAvatar from './avatar.jpg'
import rawCat from './cat.png'
import rawConfettiPopStatic from './confetti-pop-static.png'
import rawConfettiPop from './confetti-pop.gif'
import { ReactComponent as RawSquiggleBrightLeft } from './squiggle-bright-left.svg'
import { ReactComponent as RawSquiggleBrightRight } from './squiggle-bright-right.svg'
import rawSquiggle from './squiggle.svg'

export const squiggle = rawSquiggle
export const confettiPop = rawConfettiPop
export const confettiPopStatic = rawConfettiPopStatic
export const avatar = rawAvatar
export const cat = rawCat
export const avatarAvif = rawAvatarAvif
export const SquiggleBrightLeft = RawSquiggleBrightLeft
export const SquiggleBrightRight = RawSquiggleBrightRight
