import { FC } from 'react'
import { slugify } from '../../utils'
import { StyledDivider } from '.'

type Props = {
  label: string
}

const Divider: FC<Props> = ({ label }) => {
  return (
    <StyledDivider id={slugify(label)}>
      <hr />
      <div className="divider-label">
        <span>{label}</span>
      </div>
    </StyledDivider>
  )
}

export default Divider
