import styled from 'styled-components'

const StyledContact = styled.section`
  max-width: 750px;
  margin: 0 auto;
  padding: 30px 20px;
  width: 100%;

  form {
    .input-row {
      display: grid;
      grid-gap: 8px;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

      .input-container {
        border-radius: 6px;
        position: relative;
        display: flex;
        align-items: center;
        background-color: ${({ theme }) => theme.color.background};
        border: 1px solid ${({ theme }) => theme.color.gray[300]};
        transition: 0.2s;

        svg {
          position: absolute;
          pointer-events: none;
          left: 8px;
          height: 24px;
          width: 24px;
          color: ${({ theme }) => theme.color.gray[400]};

          path,
          circle {
            stroke-width: 1.5px;
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }

        input {
          height: 40px;
          padding: 0 1rem 0 40px;
          width: 100%;
          background-color: transparent;
          color: ${({ theme }) => theme.color.gray[700]};

          &::placeholder {
            color: ${({ theme }) => theme.color.gray[400]};
          }
        }
      }
    }
  }

  .textarea-container:focus-within,
  form .input-container:focus-within {
    border-color: ${({ theme }) => theme.color.gray[400]};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.color.gray[200]};
  }

  .textarea-container {
    margin-top: 8px;
    background-color: ${({ theme }) => theme.color.background};
    border: 1px solid ${({ theme }) => theme.color.gray[300]};
    border-radius: 6px;
    transition: 0.2s;

    textarea {
      padding: 14px;
      position: relative;
      width: 100%;
      resize: none;
      height: 76px;
      box-sizing: border-box;
      color: ${({ theme }) => theme.color.gray[700]};
      background-color: transparent;

      &::placeholder {
        color: ${({ theme }) => theme.color.gray[400]};
      }
    }

    .textarea-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 14px;
      font-size: 14px;
      color: ${({ theme }) => theme.color.gray[400]};
      border-top: 1px solid ${({ theme }) => theme.color.gray[300]};

      .textarea-character-count {
        pointer-events: none;
        user-select: none;
      }

      .textarea-send-btn {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        margin: 0 -10px;
        border-radius: 6px;
        background-color: transparent;
        transition: 0.2s;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          margin-left: 8px;
          height: 18px;
          width: 18px;
        }

        &:hover {
          background-color: ${({ theme }) => theme.color.gray[100]};
        }

        &:active {
          background-color: ${({ theme }) => theme.color.gray[200]};
        }

        &:disabled {
          cursor: not-allowed;
          color: ${({ theme }) => theme.color.gray[300]};

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding: 40px 20px;
  }
`

export default StyledContact
