import { FC, ReactNode } from 'react'
import { StyledExperimentSplash } from '.'

type Props = {
  children: ReactNode
}

const ExperimentSplash: FC<Props> = ({ children }) => {
  return (
    <StyledExperimentSplash>
      <div className="splash">
        <h1>{children}</h1>
      </div>
    </StyledExperimentSplash>
  )
}

export default ExperimentSplash
