import { FC } from 'react'
import { projects } from '../../data'
import { ProjectItem } from '..'
import { StyledProjects } from '.'

const Projects: FC = () => {
  return (
    <StyledProjects>
      <ul className="project-list">
        {projects.map((project, index) => {
          return <ProjectItem project={project} key={index} />
        })}
      </ul>
    </StyledProjects>
  )
}

export default Projects
