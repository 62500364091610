import styled from 'styled-components'

const StyledSizzle = styled.section`
  position: relative;
  display: grid;
  align-content: center;

  .sizzle-sizer {
    max-width: 750px;
    margin: 0 auto;
    padding: 40px 20px;
    width: 100%;
    min-height: 275px;
    display: grid;
    align-content: center;
    gap: 18px;
  }

  .scribble-indicator {
    position: relative;
    width: fit-content;
    user-select: none;

    p {
      font-family: ${({ theme }) => theme.type.scribble};
      color: ${({ theme }) => theme.color.gray[500]};
      font-size: 14px;
      letter-spacing: -2%;
    }

    svg {
      position: absolute;

      &.squiggle-left {
        top: -14px;
        left: -14px;
      }

      &.squiggle-right {
        bottom: -8px;
        right: -16px;
      }
    }
  }

  h1 {
    font-family: ${({ theme }) => theme.type.garnett};
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    max-width: 650px;
    letter-spacing: -0.02rem;
    color: ${({ theme }) => theme.color.gray[800]};

    i {
      font-family: ${({ theme }) => theme.type.scribble};
      font-size: 22px;
      font-style: normal;
      color: ${({ theme }) => theme.color.brand};
    }

    code {
      font-family: ${({ theme }) => theme.type.cascadia};
      background-color: ${({ theme }) => theme.color.brand}15;
      color: ${({ theme }) => theme.color.brand};
      border-radius: 6px;
      font-weight: 400;
      font-size: 24px;
      padding: 4px 6px;
    }

    br {
      display: none;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    min-height: 375px;

    h1 {
      font-size: 38px;
      line-height: 44px;

      br {
        display: unset;
      }

      i {
        font-size: 26px;
      }

      code {
        font-size: 30px;
      }
    }
  }
`

export default StyledSizzle
