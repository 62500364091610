import styled from 'styled-components'
import { squiggle } from '../../assets'

const StyledHeader = styled.header`
  height: 60px;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.elevation.override};
  background-color: ${({ theme }) => theme.color.background};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  user-select: none;

  .header {
    max-width: 750px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-icon {
      color: ${({ theme }) => theme.color.brand};
    }

    nav {
      display: flex;
      align-items: center;
    }
  }
`

export default StyledHeader
