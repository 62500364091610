import styled from 'styled-components'

const StyledPosts = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 30px 20px;
  width: 100%;

  .post-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding: 40px 20px;
  }
`

export default StyledPosts
