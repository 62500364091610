import { FC, useState } from 'react'
import { useReward } from 'react-rewards'
import { confettiPop, confettiPopStatic } from '../../assets'
import { StyledCelebrateButton } from '.'

const CelebrateButton: FC = () => {
  const [isConfetti, setIsConfetti] = useState(false)
  const { reward } = useReward('confetti', 'confetti', {
    angle: 90,
    elementSize: 10,
    elementCount: 200,
    startVelocity: 55
  })

  const popIt = () => {
    if (isConfetti) {
      return
    }

    setIsConfetti(true)
    reward()
    setTimeout(() => {
      setIsConfetti(false)
    }, 1300)
  }

  return (
    <StyledCelebrateButton aria-label="Celebrate" disabled={isConfetti} onClick={() => popIt()}>
      <img src={isConfetti ? confettiPop : confettiPopStatic} className="confetti-anim" alt="" />
    </StyledCelebrateButton>
  )
}

export default CelebrateButton
