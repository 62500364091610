import { FC } from 'react'
import { ReactSketchCanvas } from 'react-sketch-canvas'
import { theme } from '../../utils'
import { StyledCanvas } from '.'

const Canvas: FC = () => {
  return (
    <StyledCanvas>
      <ReactSketchCanvas
        className="canvas"
        canvasColor="transparent"
        strokeWidth={3}
        strokeColor={theme.color.brand}
        exportWithBackgroundImage={false}
      />
    </StyledCanvas>
  )
}

export default Canvas
