import { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ScrollHandler } from './components'
import { Global, ROUTES, theme } from './utils'
import {
  CircleView,
  DallEView,
  Dreambooth,
  HireView,
  PortfolioView,
  ThreadsView,
  WhoopsView
} from './views'

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Global />
      <Router>
        <Switch>
          <Route path={ROUTES.App.home} component={PortfolioView} exact />
          {/* <Route path={ROUTES.App.hire} component={HireView} exact /> */}
          <Route path={ROUTES.App.experiments.dreambooth} component={Dreambooth} exact />
          <Route path={ROUTES.App.experiments.dalleE} component={DallEView} exact />
          <Route path={ROUTES.App.experiments.circle} component={CircleView} exact />
          <Route path={ROUTES.App.experiments.threads} component={ThreadsView} exact />
          <Route path="*" component={WhoopsView} />
        </Switch>
        <Toaster />
        <ScrollHandler />
      </Router>
    </ThemeProvider>
  )
}

export default App
