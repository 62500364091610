import { FC } from 'react'

const LogoIcon: FC = () => {
  return (
    <svg className="logo-icon" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.4142 13.4142C27.7893 13.7893 28 14.298 28 14.8284V26C28 27.1046 27.1046 28 26 28H14.8284C14.298 28 13.7893 27.7893 13.4142 27.4142L1.7071 15.7071C1.07714 15.0771 1.52331 14 2.41421 14H12C13.1046 14 14 13.1046 14 12V2.41421C14 1.52331 15.0771 1.07714 15.7071 1.70711L27.4142 13.4142Z" fill="currentcolor"/>
    </svg>
  )
}

export default LogoIcon
