import { FC } from 'react'
import toast from 'react-hot-toast'
import { GlobeIcon, LinkedInIcon, RedditIcon, TwitterIcon } from '../../../assets'
import { PostType } from '../../../types'
import { StyledExperimentShareBar } from '.'

type Props = {
  experiment: PostType
}

const ExperimentShareBar: FC<Props> = ({ experiment }) => {
  const copyUrlToClipboard = async () => {
    await navigator.clipboard.writeText(window.location.href)
    toast.success('Copied URL to clipboard', {
      position: 'bottom-center'
    })
  }

  return (
    <StyledExperimentShareBar>
      <div className="share-bar">
        <span className="share-bar-label">SHARE THIS PAGE</span>
        <ul>
          <li>
            <button onClick={() => copyUrlToClipboard()}>
              <GlobeIcon />
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                window.open(
                  `https://twitter.com/intent/tweet?url=${window.location.href}&text=Check out Jamel's new article: ${experiment.title.preview}`,
                  'popup',
                  'width=600,height=600'
                )
              }
            >
              <TwitterIcon />
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                window.open(
                  `https://www.reddit.com/submit?url=${window.location.href}&title=${experiment.title.preview}`,
                  'popup',
                  'width=600,height=600'
                )
              }
            >
              <RedditIcon />
            </button>
          </li>
          <li>
            <button
              onClick={() =>
                window.open(
                  `http://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
                  'popup',
                  'width=600,height=600'
                )
              }
            >
              <LinkedInIcon />
            </button>
          </li>
        </ul>
      </div>
    </StyledExperimentShareBar>
  )
}

export default ExperimentShareBar
