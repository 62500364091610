import { FC } from 'react'
import { posts } from '../../../data'
import { ExperimentView } from '../../ExperimentView'
import { StyledDreambooth } from '.'

// Created OCT 10 2022

const Dreambooth: FC = () => {
  const body = `These are just some of the hundreds of unedited AI-generated images I was able to generate using [@mysteryguitarman](https://www.instagram.com/mysteryguitarman/)'s [Dreambooth Stable Diffusion implementation](https://github.com/JoePenna/Dreambooth-Stable-Diffusion). With "only" 5 hours of training on 2 3090s, based on 16 photos of my face and body, I have my own AI model that can generate images of myself according to any prompt I provide. It's honestly mind-blowing...`

  return (
    <ExperimentView experiment={posts[2]} body={body}>
      <StyledDreambooth>
        {Array.from(Array(39)).map((x, index) => {
          return (
            <div className="dreambooth-img">
              <img src={`/images/dreambooth/${index + 1}.png`} alt="" />
            </div>
          )
        })}
      </StyledDreambooth>
    </ExperimentView>
  )
}

export default Dreambooth
