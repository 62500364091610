import { FC } from 'react'

const GlobeIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10"/>
      <ellipse cx="12" cy="12" rx="10" ry="4" transform="rotate(90 12 12)"/>
      <path d="M2 12h20"/>
    </svg>
  )
}

export default GlobeIcon
