import styled from 'styled-components'

const StyledProjectItem = styled.li`
  display: flex;
  flex-direction: column-reverse;
  align-items: start;

  .project-info-container {
    .project-info {
      .project-title {
        font-weight: 700;
        font-size: 16px;
        color: ${({ theme }) => theme.color.gray[700]};
        font-family: ${({ theme }) => theme.type.garnett};
      }

      .project-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02rem;
        max-width: 350px;
        margin-top: 4px;
        color: ${({ theme }) => theme.color.gray[600]};
      }

      .project-details {
        display: none;
        align-items: center;
        margin: 10px 0 0 0;

        .project-tags {
          display: flex;
          list-style-type: none;
          padding: 0;
          pointer-events: none;
          user-select: none;

          .project-tag {
            height: 26px;
            padding: 2px 12px 0 12px;
            border-radius: 50rem;
            display: flex;
            font-size: 13px;
            align-items: center;
            font-family: ${({ theme }) => theme.type.cascadia};
            color: ${({ theme }) => theme.color.gray[500]};
            background-color: ${({ theme }) => theme.color.gray[200]};

            &:not(:last-child) {
              margin-right: 6px;
            }
          }
        }

        hr {
          height: 1rem;
          width: 1px;
          border: none;
          margin: 0 8px;
          flex-shrink: 0;
          display: none;
          background-color: ${({ theme }) => theme.color.gray[300]};
        }

        .project-platforms {
          display: none;
          align-items: center;
          list-style-type: none;
          padding: 0;

          .project-platform {
            display: flex;
            align-items: center;
            justify-content: center;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.2s;
              color: ${({ theme }) => theme.color.gray[500]};

              svg {
                height: 20px;
                width: 20px;
              }

              &:hover {
                color: ${({ theme }) => theme.color.gray[600]};
              }
            }

            &:not(:first-child) {
              margin-left: 6px;
            }
          }
        }
      }
    }

    .project-link {
      margin-top: 22px;
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      font-family: ${({ theme }) => theme.type.cascadia};
      color: ${({ theme }) => theme.color.gray[500]};
      outline: none;
      transition: 0.2s;
      width: fit-content;
      border-radius: 6px;
      user-select: none;

      .project-link-icon {
        height: 26px;
        width: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.1);
        color: ${({ theme }) => theme.color.gray[500]};
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        background-color: ${({ theme }) => theme.color.background};

        svg {
          transition: 0.2s ease-in-out;
          height: 18px;
          position: absolute;

          &.arrow-hover {
            transform: translate(-16px, 16px);
          }
        }
      }

      &:hover {
        color: ${({ theme }) => theme.color.gray[600]};

        .project-link-icon {
          svg {
            &.arrow-non-hover {
              transform: translate(16px, -16px);
            }

            &.arrow-hover {
              transform: translate(0, 0);
            }
          }
        }
      }
    }
  }

  .tilt-handler {
    width: 100%;
    pointer-events: none;
    border-radius: 6px;
    overflow: hidden;
  }

  .project-img {
    width: 100%;
    padding-top: 56.25%;
    display: block;
    position: relative;
    border-radius: 6px;
    margin-bottom: 1rem;
    user-select: none;
    transition: 0.2s ease-in-out box-shadow;

    .video-button {
      position: absolute;
      left: 10px;
      bottom: 10px;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      z-index: 3;
      background: #0000006b;
      color: ${({ theme }) => theme.color.background};
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;

      svg {
        height: 20px;
      }

      &:hover,
      &:focus-visible,
      &:focus {
        background: #000000ab;

        & + video {
          display: flex;
          z-index: 1;
        }
      }
    }

    video {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      z-index: 4;
      pointer-events: none;
      box-shadow: inset 0px -4px 20px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    }

    &:hover {
      box-shadow: 0 4px 10px rgb(0, 0, 0, 0.2);
    }
  }

  .project-code {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 6px;
    margin-bottom: 1rem;
    pointer-events: none;
    user-select: none;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: none;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      box-shadow: inset 0px -4px 20px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    grid-template-columns: minmax(0, 1fr) 294px;
    display: grid;
    grid-gap: 30px;

    .project-img {
      margin-bottom: 0;
    }

    .tilt-handler {
      pointer-events: all;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .project-info-container {
      .project-info {
        .project-details {
          hr {
            display: block;
          }

          .project-platforms {
            display: flex;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.xs}px) {
    .project-info-container {
      .project-info {
        .project-details {
          display: flex;
        }
      }
    }
  }
`

export default StyledProjectItem
