import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { cat } from '../../assets'
import { ROUTES } from '../../utils'
import { StyledWhoopsView } from '.'

const WhoopsView: FC = () => {
  const urls = [
    'https://tailwindcss.com/docs/customizing-colors',
    'https://www.reddit.com/r/webdev',
    'https://www.underconsideration.com/brandnew/',
    'https://land-book.com/',
    'https://heroicons.com/'
  ]

  const getRandomUrl = () => {
    return urls[Math.floor(Math.random() * urls.length)]
  }

  return (
    <StyledWhoopsView>
      <Helmet>
        <title>404 | Jamel Hammoud</title>
      </Helmet>
      <div className="whoops-text">
        <div className="whoops-icon">
          <img src={cat} alt="" />
        </div>
        <h2>Sorry, I lost that page.</h2>
        <div className="whoops-actions">
          <Link to={ROUTES.App.home}>Go Home</Link>
          <a href={getRandomUrl()} target="_blank" rel="noreferrer noopener" className="random-btn">
            See Something Random
          </a>
        </div>
      </div>
      <div className="bubbles">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </StyledWhoopsView>
  )
}

export default WhoopsView
