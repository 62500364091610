import { FC } from 'react'

const EnvelopeIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6z"/>
      <path d="M2 8l7.501 6.001a4 4 0 0 0 4.998 0L22 8"/>
    </svg>
  )
}

export default EnvelopeIcon
