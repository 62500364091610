import styled from 'styled-components'

const StyledExperimentAbout = styled.div`
  position: relative;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   opacity: 0.15;
  //   background-color: var(--main-color);
  // }

  .about {
    max-width: 750px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    position: relative;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.02rem;
    color: ${({ theme }) => theme.color.gray[600]};

    ol, ul {
      display: grid;
      gap: 12px;
      margin: 20px 0 0 0;

      li {
        padding-left: 12px;

        &::marker {
          color: ${({ theme }) => theme.color.gray[500]};
        }
      }
    }

    a {
      z-index: 1;
      position: relative;
      text-decoration: none;
      color: ${({ theme }) => theme.color.blue[700]};
      border-radius: 6px;
      transition: 0.2s;

      &:hover {
        background-color: ${({ theme }) => theme.color.blue[100]};
        box-shadow: 5px 0 0 ${({ theme }) => theme.color.blue[100]}, -5px 0 0 ${({ theme }) =>
  theme.color.blue[100]};
      }
    }

    code {
      font-size: 15px;
      border-radius: 4px;
      font-family: ${({ theme }) => theme.type.cascadia};
      background-color: ${({ theme }) => theme.color.gray[200]};
      padding: 5px 5px 4px 5px;
      position: relative;
      bottom: 1px;
    }

    p, blockquote {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    blockquote {
      margin-block: 0;
      margin-inline: 0;
      padding-left: 20px;
      border-radius: 6px;
      color: ${({ theme }) => theme.color.gray[600]};
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        border-radius: 6px;
        background-color: ${({ theme }) => theme.color.gray[400]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    .about {
      padding: 40px 20px;
      font-size: 20px;
      line-height: 32px;

      ol, ul {
        display: grid;
        grid-gap: 20px;
        margin: 26px 0 0 0;
      }

      p, blockquote {
        &:not(:first-child) {
          margin-top: 26px;
        }
      }

      blockquote {
        padding-left: 26px;
      }
  }
`

export default StyledExperimentAbout
