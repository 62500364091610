import styled from 'styled-components'

const StyledToolsOfTrade = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  color: ${({ theme }) => theme.color.gray[500]};
  font-family: ${({ theme }) => theme.type.cascadia};
  font-size: 14px;
  line-height: 17px;

  .tools {
    max-width: 750px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    padding: 0 20px;

    span {
      flex-shrink: 0;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0 0 0 20px;
      padding: 0;
      list-style-type: none;
      overflow-x: auto;

      li {
        text-align: right;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-right: 12px;
          padding-right: 12px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            height: 100%;
            right: 0;
            width: 1px;
            background-color: ${({ theme }) => theme.color.gray[300]};
          }
        }
      }
    }
  }
`

export default StyledToolsOfTrade
