import capacitorJSImg from '../assets/images/experiments/capacitorjs/capacitorjs.png'
import circleImg from '../assets/images/experiments/circle/circle.jpeg'
import dalleImg from '../assets/images/experiments/dalle/dalle.jpeg'
import { PostType } from '../types'
import { ROUTES, theme } from '../utils'

export const posts: PostType[] = [
  {
    _id: 'dalle',
    ogImage: '/images/posts/dalle.png',
    image: dalleImg,
    color: theme.color.gray[900],
    title: {
      preview: "Fooling around with OpenAI's Dall•E image generation.",
      component: (
        <>
          Fooling around with OpenAI's <br /> Dall•E image generation.
        </>
      )
    },
    description: "Using OpenAI's DALL•E to generate images from unique text prompts.",
    keywords: 'DALLE, OpenAI, AI art, generated art, art, AI',
    url: ROUTES.App.experiments.dalleE
  },
  {
    _id: 'circle',
    ogImage: '/images/posts/circle.png',
    image: circleImg,
    color: '#3E45EB',
    title: {
      preview: 'Building an OAuth Flow for Circle.so’s Custom SSO.',
      component: (
        <>
          Building an OAuth Flow <br /> for Circle.so’s Custom SSO.
        </>
      )
    },
    description:
      "Custom single sign-on isn't easy. Here's an overview of how I did it for Social Curator's Circle.so-powered community.",
    keywords: 'Circle.so, SSO, single sign-on, OAuth, custom SSO',
    url: ROUTES.App.experiments.circle
  },
  {
    _id: 'dreambooth',
    ogImage: '/images/posts/dreambooth.png',
    image: '/images/dreambooth/3.png',
    color: theme.color.blue[900],
    title: {
      preview: 'Using Dreambooth (Stable Diffusion) to generate images of myself.',
      component: <>Using Dreambooth (Stable Diffusion) to generate self-portraits.</>
    },
    description:
      'We are living in the future. Leveraging the power of open-source Stable Diffusion, I trained an AI model to generate images of me based on a text prompt.',
    keywords:
      'Stable Diffusion, Dreambooth, AI art, artificial intelligence, AI model, open-source, opensource',
    url: ROUTES.App.experiments.dreambooth
  }
  // {
  //   _id: 'threads',
  //   ogImage: '/images/posts/threads.png',
  //   image: '/images/posts/threads-icon.png',
  //   color: '#BC14F4',
  //   title: {
  //     preview: 'Reverse engineering the Threads API in 72 hours.',
  //     component: (
  //       <>
  //         Reverse engineering <br /> Instagram's Threads API.
  //       </>
  //     )
  //   },
  //   description: "How I reverse-engineered Instagram's Threads API within 72 hours.",
  //   keywords:
  //     'Threads, Instagram, API, Threads API, Instagram Threads, open-source, opensource, repo',
  //   url: ROUTES.App.experiments.threads
  // }
  // {
  //   image: capacitorJSImg,
  //   color: '#119eff',
  //   title: {
  //     preview: 'CapacitorJS: what is it, and how can I leverage it to create ReactJS mobile apps?',
  //     component: <></>
  //   },
  //   description: 'Think React Native is too bulky, and prefer ReactJS? I do too. Now it\'s possible to create entire native-level applications using JavaScript with CapacitorJS.',
  //   keywords: 'CapacitorJS, mobile ReactJS, React Native',
  //   url: ROUTES.App.experiments.dreambooth
  // },
]
