import styled from 'styled-components'

const StyledFooter = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  color: ${({ theme }) => theme.color.gray[500]};
  padding-bottom: 80px;

  .play-btn {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
    justify-content: center;
    font-size: 14px;
    letter-spacing: 0.08rem;
    transition: 0.2s;
    margin-bottom: -8px;
    user-select: none;
    color: ${({ theme }) => theme.color.gray[600]};
    background-color: ${({ theme }) => theme.color.background};
    font-family: ${({ theme }) => theme.type.cascadia};

    svg {
      height: 24px;
      width: 24px;
      margin-right: 6px;
      color: ${({ theme }) => theme.color.gray[500]};
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.gray[200]};
    }
  }

  .footer {
    max-width: 750px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;

    .footer-top {
      display: flex;
      align-items: start;
      justify-content: space-between;

      .prompt-desktop {
        display: none;
      }

      .avatar {
        display: block;
        height: 30px;
        width: 30px;
        user-select: none;
        background-color: ${({ theme }) => theme.color.background};
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        margin-bottom: -6px;
        pointer-events: none;

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        }
      }

      p {
        line-height: 20px;
      }

      .footer-socials {
        display: flex;

        .footer-social {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.2s;
          outline: none;
          border-radius: 6px;
          color: ${({ theme }) => theme.color.gray[500]};

          svg {
            height: 24px;
          }

          &:not(:first-child) {
            margin-left: 12px;
          }

          &:hover {
            color: ${({ theme }) => theme.color.gray[600]};
          }
        }
      }
    }

    hr {
      height: 1px;
      border: none;
      margin: 18px 0;
      background-color: ${({ theme }) => theme.color.gray[300]};
    }

    .footer-bottom {
      display: flex;
      justify-content: space-between;

      .footer-page-details {
        display: contents;

        span:first-child {
          margin-right: 18px;
        }

        span {
          display: flex;
          align-items: center;

          svg {
            margin: 0px 5px;
            position: relative;
            bottom: 1px;
          }
        }
      }

      .footer-me-link {
        display: none;
        align-items: center;
        text-decoration: none;
        position: relative;
        color: ${({ theme }) => theme.color.gray[500]};

        svg {
          position: relative;
          top: -1px;
          height: 20px;
          margin-left: 2px;

          path {
            stroke-width: 2.15px;
          }
        }

        &:hover {
          color: ${({ theme }) => theme.color.brand};
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding-bottom: 0;

    .footer {
      padding: 40px 20px;

      .footer-top {
        .prompt-desktop {
          display: block;
        }

        .avatar {
          display: none;
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    .footer {
      .footer-bottom {
        .footer-page-details {
          display: flex;
        }

        .footer-me-link {
          display: flex;
        }
      }
    }
  }
`

export default StyledFooter
