import { FC } from 'react'
import { StyledToolsOfTrade } from '.'

const ToolsOfTrade: FC = () => {
  return (
    <StyledToolsOfTrade>
      <div className="tools">
        <span className="tools-label">WHAT I USE</span>
        <ul>
          <li>TypeScript</li>
          <li>React</li>
          <li>NodeJS</li>
          <li>MongoDB</li>
          <li>GraphQL</li>
        </ul>
      </div>
    </StyledToolsOfTrade>
  )
}

export default ToolsOfTrade
