import { FC, FormEvent, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import TextareaAutosize from 'react-textarea-autosize'
import { EnvelopeIcon, FaceHappyIcon, SendIcon } from '../../assets'
import { theme } from '../../utils'
import { StyledContact } from '.'

const Contact: FC = () => {
  const [fullName, setFullName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [body, setBody] = useState('')
  const [areaKey, setAreaKey] = useState(0)

  const encodeForm = (data: any) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  // Submits the form's content to Netlify
  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault()

      await fetch('/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: encodeForm({
          'form-name': 'contact',
          name: fullName,
          email: emailAddress,
          body: body
        })
      })

      toast.success('I Got Your Message', {
        iconTheme: {
          primary: theme.color.brand,
          secondary: theme.color.background
        },
        style: {
          padding: '8px 6px 8px 10px'
        },
        position: 'bottom-left'
      })

      setFullName('')
      setEmailAddress('')
      setBody('')
    } catch {
      toast.error('Whoops! Something went wrong', {
        iconTheme: {
          primary: theme.color.brand,
          secondary: theme.color.background
        },
        style: {
          padding: '8px 6px 8px 10px'
        },
        position: 'bottom-left'
      })
    }
  }

  // Returns true if the form cannot be submitted
  const isDisabled = () => {
    const filledName = !!fullName.trim().length
    const filledEmail = !!emailAddress.trim().length
    const filledBody = !!body.trim().length

    if (!filledName || !filledEmail || !filledBody) {
      return true
    }

    return false
  }

  useEffect(() => {
    setTimeout(() => setAreaKey(69), 50)
  }, [])

  return (
    <StyledContact>
      <form method="post" onSubmit={(e) => handleSubmit(e)}>
        <div className="input-row">
          <div className="input-container">
            <FaceHappyIcon />
            <input
              placeholder="Full Name"
              aria-label=""
              type="text"
              lang="en"
              name="name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="input-container">
            <EnvelopeIcon />
            <input
              placeholder="Email Address"
              aria-label=""
              type="email"
              lang="en"
              name="email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="textarea-container">
          <TextareaAutosize
            placeholder="What do you want me to know?"
            value={body}
            minRows={3}
            maxRows={10}
            key={areaKey}
            name="body"
            onChange={(e) => setBody(e.target.value)}
          />
          <div className="textarea-actions">
            <span className="textarea-character-count">
              {body.length} Character{body.length !== 1 && 's'}
            </span>
            <button className="textarea-send-btn" disabled={isDisabled()}>
              Send <SendIcon />
            </button>
          </div>
        </div>
        <input type="hidden" name="form-name" value="contact" />
      </form>
      <Toaster />
    </StyledContact>
  )
}

export default Contact
