import styled from 'styled-components'

const StyledDivider = styled.div`
  width: 100%;
  position: relative;
  user-select: none;

  hr {
    height: 1px;
    border: none;
    background-color: ${({ theme }) => theme.color.gray[300]};
  }

  .divider-label {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 750px;
    margin: 0 auto;
    padding: 40px 20px;
    width: 100%;

    span {
      background-color: ${({ theme }) => theme.color.gray[100]};
      color: ${({ theme }) => theme.color.gray[500]};
      font-family: ${({ theme }) => theme.type.cascadia};
      font-size: 14px;
      line-height: 17px;
      padding: 0 1rem;
      margin: 0 -1rem;
    }
  }
`

export default StyledDivider
