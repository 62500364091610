import styled from 'styled-components'

const StyledCelebrateButton = styled.button`
  height: 65px;
  width: 65px;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  z-index: ${({ theme }) => theme.elevation.override};
  color: ${({ theme }) => theme.color.background};
  background-color: ${({ theme }) => theme.color.brand};
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 7px rgba(0, 0, 0, 0.1);

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    filter: invert(1);
  }

  &:active {
    transform: rotate(4deg) scale(0.95);
  }
`

export default StyledCelebrateButton
