import styled from 'styled-components'

const StyledDreambooth = styled.div`
  padding: 0 20px 20px 20px;
  max-width: 750px;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3px;

  .dreambooth-img {
    padding-top: 100%;
    border-radius: 2px;
    pointer-events: none;
    user-select: none;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border-radius: 2px;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border-radius: 2px;
      height: 100%;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding: 0 20px 40px 20px;
    grid-gap: 10px;

    .dreambooth-img {
      box-shadow: 0 2px 12px #00000042;
    }
  }
`

export default StyledDreambooth
