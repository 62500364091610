import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ArrowUpRightIcon, avatar, avatarAvif } from '../../../assets'
import { ROUTES } from '../../../utils'
import { StyledExperimentHeader } from '.'

const ExperimentHeader: FC = () => {
  return (
    <StyledExperimentHeader>
      <Link to={ROUTES.App.home} className="header">
        <span className="header-title">
          <div className="avatar">
            <picture>
              <source srcSet={avatarAvif} type="image/avif" />
              <img src={avatar} alt="Jamel Hammoud" />
            </picture>
          </div>
          WRITING
        </span>
        <button>
          Back to Portfolio <ArrowUpRightIcon />
        </button>
      </Link>
    </StyledExperimentHeader>
  )
}

export default ExperimentHeader
