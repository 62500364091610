import { FC, ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import {
  ExperimentAbout,
  ExperimentHeader,
  ExperimentShareBar,
  ExperimentSplash,
  Footer
} from '../../components'
import { PostType } from '../../types'
import { StyledExperimentView } from '.'

type Props = {
  experiment: PostType
  body: string
  children?: ReactNode
}

const ExperimentView: FC<Props> = ({ experiment, body, children }) => {
  return (
    <StyledExperimentView color={experiment.color}>
      <Helmet>
        <title>{experiment.title.preview} | Jamel Hammoud</title>
        <meta property="description" content={experiment.description} />
        <meta
          name="keywords"
          content="Jamel Hammoud, Jamel, Hammoud, Software Developer, Software Engineer, Web Developer, Full Stack Developer, Programmer"
        ></meta>

        <meta property="og:description" content={experiment.description} />
        <meta property="og:title" content={experiment.title.preview} />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Jamel Hammoud" />
        <meta property="og:site_name" content="jamelhammoud.com" />
        <meta
          property="og:url"
          content={`https://www.jamelhammoud.com${window.location.pathname}`}
        />

        <meta property="og:image" content={`https://www.jamelhammoud.com${experiment.ogImage}`} />
        <meta property="og:image:alt" content={experiment.title.preview} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />

        <meta name="theme-color" content={experiment.color} />
      </Helmet>
      <ExperimentHeader />
      <ExperimentSplash>{experiment.title.component || experiment.title.preview}</ExperimentSplash>
      <ExperimentShareBar experiment={experiment} />
      <ExperimentAbout about={body} />
      {children}
      <Footer />
    </StyledExperimentView>
  )
}

export default ExperimentView
