import { FC, useEffect, useState } from 'react'
import { LogoIcon } from '../../assets'
import { HeaderLink } from '..'
import { StyledHeader } from '.'

const Header: FC = () => {
  const SECTIONS = ['about-me', 'writing', 'experiments', 'contact'] as const
  const [active, setActive] = useState<(typeof SECTIONS)[number] | undefined>(undefined)

  const handleScroll = () => {
    const scroll = window.scrollY

    for (let i = SECTIONS.length - 1; i >= 0; i--) {
      const section = SECTIONS[i]
      const scrollElement = document.getElementById(section)

      if (!scrollElement) {
        setActive(undefined)
        break
      }

      const scrollOffset = scrollElement.getBoundingClientRect().top + scroll

      if (scroll >= scrollOffset - 80) {
        setActive(section)
        break
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <StyledHeader>
      <div className="header">
        <LogoIcon />
        <nav>
          <HeaderLink label="About" reference="about-me" curr={active} hideMobile />
          <HeaderLink label="Writing" reference="writing" curr={active} hideMobile />
          <HeaderLink label="Experiments" reference="experiments" curr={active} hideMobile />
          <HeaderLink label="Contact" reference="contact" curr={active} hideMobile />
          {/* <HeaderLink label="Resume" link="/pdf/resume.pdf" /> */}
        </nav>
      </div>
    </StyledHeader>
  )
}

export default Header
