import { FC } from 'react'

const FaceHappyIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <circle cx="12" cy="12" r="10"/>
      <path d="M8 9.05v-.1"/>
      <path d="M16 9.05v-.1"/>
      <path d="M16 14c-.5 1.5-1.79 3-4 3s-3.5-1.5-4-3"/>
    </svg>
  )
}

export default FaceHappyIcon
