import styled, { keyframes } from 'styled-components'

const bubbleAnim = keyframes`
  0%{
    transform: translateY(0) rotate(0deg);
  }

  100%{
    transform: translateY(-200vh) rotate(720deg);
  }
`

const StyledWhoopsView = styled.div`
  padding: 4rem;
  height: 100vh;
  display: flex;
  background-color: ${({ theme }) => theme.color.gray[200]};
  align-items: center;
  justify-content: center;

  .whoops-text {
    max-width: 500px;
    margin: 0 auto;

    .whoops-icon {
      display: flex;
      pointer-events: none;
      user-select: none;
      margin-bottom: 10px;

      img {
        height: 80px;
      }
    }

    h1 {
      font-family: ${({ theme }) => theme.type.oli};
      font-weight: 400;
      font-size: 48px;
      letter-spacing: -0.02rem;
      color: ${({ theme }) => theme.color.gray[700]};
    }
    
    h2 {
      font-weight: 400;
      font-size: 18px;
      color: ${({ theme }) => theme.color.gray[600]};
    }

    .whoops-actions {
      display: flex;
      margin-top: 32px;

      a {
        padding: 6px 10px;
        display: flex;
        border-radius: 6px;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        transition: 0.2s;
        user-select: none;
        color: ${({ theme }) => theme.color.gray[500]};
        background-color: ${({ theme }) => theme.color.gray[300]};

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.random-btn {
          color: ${({ theme }) => theme.color.gray[600]};
          background-color: ${({ theme }) => theme.color.background};
          box-shadow: 0 1px 1px rgba(0,0,0,0.02), 
            0 2px 2px rgba(0,0,0,0.02), 
            0 4px 4px rgba(0,0,0,0.02), 
            0 8px 8px rgba(0,0,0,0.02),
            0 16px 16px rgba(0,0,0,0.02);

          &:hover {
            box-shadow: none;
            background-color: ${({ theme }) => theme.color.background};
          }
        }

        &:hover {
          color: ${({ theme }) => theme.color.gray[600]};
        }
      }
    }
  }

  .bubbles {
    position: fixed;
    z-index: ${({ theme }) => theme.elevation.override};
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
  
    div {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 12px;
      background-color: ${({ theme }) => theme.color.gray[300]}5e;
      animation: ${bubbleAnim} 25s linear infinite;
      bottom: -150px;
      backdrop-filter: blur(2px);
      
      &:nth-child(1){
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }

      &:nth-child(2){
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
      }

      &:nth-child(3){
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
      }

      &:nth-child(4){
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
      }

      &:nth-child(5){
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
      }

      &:nth-child(6){
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
      }

      &:nth-child(7){
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }

      &:nth-child(8){
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
      }

      &:nth-child(9){
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
      }

      &:nth-child(10){
        left: 85%;
        width: 150px;
        height: 150px;
        animation-delay: 0s;
        animation-duration: 11s;
      }
    }
  }
`

export default StyledWhoopsView
