import { FC } from 'react'
import { generations, posts } from '../../../data'
import { ExperimentView } from '../..'
import { StyledDallEView } from '.'

// CREATED AUG 2 2022

const DallEView: FC = () => {
  const body = `I recently got beta access to Dall•E, an AI system created by OpenAI that can create realistic images and art from a description in natural language. The wacky images below are just some of the amazing artworks I've been able to generate.`

  return (
    <ExperimentView experiment={posts[0]} body={body}>
      <StyledDallEView>
        <div className="generations-list-container">
          <div className="generations-list">
            <ul>
              {generations.map((generation, index) => {
                return (
                  <a href={generation.url} rel="noreferrer noopener" target="_blank">
                    <li className="generation-list-item" key={index}>
                      <span className="generation-list-item-prompt">{generation.prompt}</span>
                      <img src={generation.image} alt={generation.prompt} />
                    </li>
                  </a>
                )
              })}
            </ul>
          </div>
        </div>
      </StyledDallEView>
    </ExperimentView>
  )
}

export default DallEView
