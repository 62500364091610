import { FC } from 'react'
import { SquiggleBrightLeft, SquiggleBrightRight } from '../../assets'
import { Canvas } from '..'
import { StyledSizzle } from '.'

const Sizzle: FC = () => {
  return (
    <StyledSizzle>
      <div className="sizzle-sizer">
        <div className="scribble-indicator">
          <SquiggleBrightLeft className="squiggle-left" />
          <p>Press & hold to scribble</p>
          <SquiggleBrightRight className="squiggle-right" />
        </div>
        <h1>
          Building the workplace
          <br /> of the future at Envoy.
        </h1>
        {/* <h1>Hi, I’m Jamel Hammoud, a software engineer who builds for the web.<br/> I currently work at <mark>Social Curator</mark>.</h1> */}
      </div>
      <Canvas />
    </StyledSizzle>
  )
}

export default Sizzle
