import styled from 'styled-components'

const StyledHeaderLink = styled.a`
  text-decoration: none;
  position: relative;
  outline: none;
  display: flex;
  align-items: center;
  padding: 2px 6px;
  margin: 0 -6px;
  border-radius: 6px;
  color: ${({ theme }) => theme.color.gray[500]};
  transition: 0.2s;
  font-weight: 500;

  &.hide-mobile {
    display: none;
  }

  svg {
    height: 20px;
    margin-left: 4px;
  }

  .count {
    font-size: 13px;
    padding: 2px 8px;
    margin-left: 6px;
    border-radius: 50rem;
    background-color: ${({ theme }) => theme.color.gray[200]};
  }

  &:not(:last-child) {
    margin-right: 14px;
  }

  &:hover,
  &[data-active='true'] {
    color: ${({ theme }) => theme.color.brand};
    background-color: ${({ theme }) => theme.color.brand}15;

    .count {
      background-color: ${({ theme }) => theme.color.brand}20;
      color: ${({ theme }) => theme.color.brand};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.s}px) {
    &.hide-mobile {
      display: flex;
    }
  }
`

export default StyledHeaderLink
