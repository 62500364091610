import { FC } from 'react'
import { posts } from '../../../data'
import { ExperimentView } from '../..'

// CREATED OCT 10 2022

const CircleView: FC = () => {
  const body = `The [company I work for](http://socialcurator.com) recently purchased an enterprise subscription for [Circle.so](http://Circle.so) (Circle), a white-label community platform. Before Circle, we used a Facebook group to interact with our community. Although this worked, there were 3 major pain points that prevented it from becoming a larger part of our platform’s offering:

  1. We couldn’t automatically create / link Facebook accounts to our community when a user registers for our platform. This meant that we needed to manually (yes.. manually) invite & accept each user to our Facebook group.
  2. We couldn’t remove Facebook’s branding from an embed. This meant that even if we simply “iframe’d” the Facebook community into our platform, it would still be obviously Facebook (even worst: the user could potentially figure out a way out of the [facebook.com](http://facebook.com) domain, go to Google, search our platform and *log in*, within a page *in our platform*).
  3. Our user’s perception & usage of Facebook was declining. With Facebook increasingly in the news for privacy violations, and people using Facebook less & less for connecting with their friends, our users simply weren’t incentivized to log in to their Facebook accounts (forget *creating* one).
  
  We needed to get out of Facebook, and introduce something that alleviates those 3 pain points. The solution? Circle. 
  
  The premise was simple: instead of spending design & dev hours on building out our own community platform, we remove Circle’s branding and embed the platform into a “Community” page of our web application.

  Circle provides an area from within their admin panel to configure the endpoints they'll query when a user is authenticating.

  > Something that's important to note while building the endpoints is: Circle cannot query \`localhost\`. This means that you'll have to forward your local server when testing. I recommend using something like [Ngrok](https://ngrok.com/).

  This means that we can create 3 endpoints for Circle to query:

  1. An Authorization URL (Which takes a JWT & returns an authentication token)
  2. A Token Fetch URL (Which returns an access token)
  3. A Profile Info API URL (Which returns a user's ID, email, name & avatar)

  The Authorization URL will change depending on your platform's requirements, however in this case we found it most beneficial to require a JWT for authentication. The process was simple: first we validate the JWT (we use [Firebase](https://firebase.google.com/)). Then, if the JWT is valid, we redirect the user along with a \`code\` URL parameter which is set to the BASE64'd JWT.

  The Token Fetch URL was included by Circle in case you were using a pre-built solution (like Facebook auth). Since we're not doing that, and instead building our own, all we need to do here is ensure that the provided \`client_id\` and \`client_secret\` are valid, and then respond with the same authentication token provided in the Authorization URL.

  The Profile Info API URL decodes the JWT (remember: we converted it to BASE64) and queries for the corresponding user. The, if the user exists, it returns the user's \`_id\`, \`email\` and \`name\` (we don't have avatar images for our users).

  After those 3 endpoints are configured, all you have to do is enter them into the Circle.so admin panel, and you're ready to go!
`

  return <ExperimentView experiment={posts.find((p) => p._id === 'circle')!} body={body} />
}

export default CircleView
