import { FC } from 'react'
import { ExternalLinkIcon } from '../../assets'
import { PostType } from '../../types'
import { StyledPostItem } from '.'

type Props = {
  post: PostType
}

const PostItem: FC<Props> = ({ post }) => {
  return (
    <StyledPostItem to={post.url} color={post.color}>
      <div className="post-color" />
      <div className="post-img">
        <img src={post.image} alt={post.title.preview} />
      </div>
      <blockquote className="post">
        <span className="post-title">{post.title.preview}</span>
        <span className="post-preview">{post.description}</span>
      </blockquote>
    </StyledPostItem>
  )
}

export default PostItem
