import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import { StyledExperimentAbout } from '.'

type Props = {
  about: string
}

const ExperimentAbout: FC<Props> = ({ about }) => {
  return (
    <StyledExperimentAbout>
      <div className="about">
        <ReactMarkdown linkTarget="_blank" children={about} />
      </div>
    </StyledExperimentAbout>
  )
}

export default ExperimentAbout
