import styled from 'styled-components'

const StyledDallEView = styled.div`
  .generations-list-container {
    .generations-list {
      padding: 0 20px 20px 20px;
      max-width: 750px;
      margin: 0 auto;
      width: 100%;
  
      ul {
        box-sizing: content-box;
        padding: 0;
        list-style-type: none;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  
        a {
          transition: 0.2s;
  
          .generation-list-item {
            padding-top: 100%;
            position: relative;
            border-radius: 3px;
            user-select: none;
  
            .generation-list-item-prompt {
              position: absolute;
              transition: 0.2s;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 3px;
              width: 100%;
              height: 100%;
              user-select: none;
              color: ${({ theme }) => theme.color.gray[700]};
              font-family: ${({ theme }) => theme.type.scribble};
              z-index: 1;
              padding: 20px;
              font-size: 18px;
              opacity: 0;
              background-color: rgba(255, 255, 255, 0.9);
            }
    
            img {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 3px;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
      
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 3px;
              width: 100%;
              height: 100%;
              z-index: 3;
              box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
            }
          }
    
          &:hover {
            transform: rotate(0deg) scale(1.05);
  
            .generation-list-item {
              .generation-list-item-prompt {
                opacity: 1;
                background-color: rgba(255, 255, 255, 0.9);
                backdrop-filter: blur(3px) saturate(3);
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    .generations-list-container {
      .generations-list {
        padding: 0 20px 40px 20px;
      }
    }
  }
`

export default StyledDallEView
