import { FC, createRef, useEffect, useRef, useState } from 'react'
import { ArrowClockwiseIcon, FrownIcon, SmileIcon } from '../../assets'
import { StyledGame } from '.'

const Game: FC = () => {
  const [score, setScore] = useState(0)
  const [hasJumped, setHasJumped] = useState(false)
  const [isJumping, setIsJumping] = useState(false)
  const [gameOver, setGameOver] = useState(false)
  const playerRef = useRef(createRef<HTMLDivElement>())
  const gameRef = useRef(createRef<HTMLDivElement>())
  const gameOverRef = useRef(gameOver)
  gameOverRef.current = gameOver

  const handleJump = () => {
    if (isJumping || gameOver) {
      return
    }

    setIsJumping(true)
    setHasJumped(true)
    setTimeout(() => {
      if (gameOverRef.current) {
        return
      }

      setIsJumping(false)
    }, 400)
  }

  const removeAllRocks = () => {
    document.querySelectorAll('[data-rock]').forEach((rock) => {
      rock.remove()
    })
  }

  const handleAddRock = () => {
    if (gameOver) {
      return
    }

    const random = Math.floor(Math.random() * (2000 - 500 + 1) + 500)
    const rock = document.createElement('div')

    rock.className = 'rock'
    rock.setAttribute('data-rock', 'true')

    gameRef.current?.current?.appendChild(rock)

    setTimeout(() => {
      if (gameOverRef.current) {
        return
      }

      rock.remove()
    }, 1500)

    setTimeout(() => {
      handleAddRock()
    }, random)
  }

  const handleHit = () => {
    const player = playerRef.current.current

    if (!player) {
      console.log('ERR: NO PLAYER')
      return
    }

    document.querySelectorAll('[data-rock]').forEach((rock) => {
      const rockTop = rock.getBoundingClientRect().top
      const rockHeight = rock.getBoundingClientRect().height
      const rockLeft = rock.getBoundingClientRect().left
      const rockWidth = rock.getBoundingClientRect().width
      const rockWide = rockLeft + rockWidth
      const rockTall = rockTop + rockHeight

      const playerTop = player.getBoundingClientRect().top
      const playerHeight = player.getBoundingClientRect().height
      const playerLeft = player.getBoundingClientRect().left
      const playerWidth = player.getBoundingClientRect().width
      const playerWide = playerLeft + playerWidth
      const playerTall = playerTop + playerHeight

      if (playerTall >= rockTall && playerWide < rockWide && playerWide > rockLeft) {
        setGameOver(true)
      }
    })
  }

  const restartGame = () => {
    removeAllRocks()
    setScore(0)
    setIsJumping(false)
    setGameOver(false)
  }

  useEffect(() => {
    if (gameOver) {
      return
    }

    const scoreInterval = setInterval(() => {
      setScore((score) => score + 1)
    }, 100)

    const hitInterval = setInterval(handleHit, 10)

    return () => {
      clearInterval(scoreInterval)
      clearInterval(hitInterval)
    }
  }, [gameOver])

  useEffect(() => {
    handleAddRock()
  }, [])

  return (
    <StyledGame
      ref={gameRef.current}
      isJumping={isJumping}
      gameOver={gameOver}
      onClick={() => handleJump()}
    >
      {gameOver && (
        <div className="game-over-screen">
          <span>GAME OVER</span>
          <button className="restart-btn" onClick={() => restartGame()}>
            <ArrowClockwiseIcon /> RESTART
          </button>
        </div>
      )}
      <span className="score">{score}</span>
      {!hasJumped && !gameOver && <span className="controls">CLICK TO JUMP</span>}
      <div className="player" ref={playerRef.current}>
        {gameOver ? <FrownIcon /> : <SmileIcon />}
      </div>
      <div className="rocks" />
      <div className="base" />
    </StyledGame>
  )
}

export default Game
