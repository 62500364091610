import { FC } from 'react'
import Tilt from 'react-parallax-tilt'
import {
  AppStoreIcon,
  ArrowUpRightIcon,
  DiscordIcon,
  GitHubIcon,
  GlobeIcon,
  PlayIcon,
  RedditIcon,
  SteamIcon
} from '../../assets'
import { ProjectType } from '../../types'
import { StyledProjectItem } from '.'

type Props = {
  project: ProjectType
}

const ProjectItem: FC<Props> = ({ project }) => {
  const getPlatformIcon = (platform: string) => {
    switch (platform) {
      case 'Reddit':
        return <RedditIcon />
      case 'Steam':
        return <SteamIcon />
      case 'App Store':
        return <AppStoreIcon />
      case 'GitHub':
        return <GitHubIcon />
      case 'Discord':
        return <DiscordIcon />
      case 'Web':
        return <GlobeIcon />
    }
  }

  return (
    <StyledProjectItem>
      <div className="project-info-container">
        <div className="project-info">
          <p className="project-title">{project.title}</p>
          <p className="project-description">{project.description}</p>
          <div className="project-details">
            <ul className="project-tags">
              {project.tags.map((tag) => {
                return (
                  <li className="project-tag" key={tag}>
                    {tag}
                  </li>
                )
              })}
            </ul>
            {project.platforms && (
              <>
                <hr />
                <ul className="project-platforms">
                  {project.platforms.map((platform) => {
                    return (
                      <li className="project-platform" key={platform.label}>
                        <a href={platform.url} rel="noopener noreferrer" target="_blank">
                          {getPlatformIcon(platform.label)}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
        {project.link && (
          <a
            href={project.link.url}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={project.link.label}
            className="project-link"
          >
            <div className="project-link-icon">
              <ArrowUpRightIcon className="arrow-non-hover" />
              <ArrowUpRightIcon className="arrow-hover" />
            </div>
            {project.link.label}
          </a>
        )}
      </div>
      {!!project.images && (
        <Tilt className="tilt-handler" scale={1.05} glareEnable>
          <a
            href={project.link?.url}
            className="project-img"
            rel="noopener noreferrer"
            target="_blank"
            aria-label={project.link?.label}
          >
            {project.videoUrl && (
              <>
                <button aria-label="Play Video" className="video-button">
                  <PlayIcon />
                </button>
                <video src={project.videoUrl} autoPlay muted />
              </>
            )}
            <picture>
              <source srcSet={`${project.images.avif}`} type="image/avif" />
              <img src={project.images.png} alt={project.title} loading="lazy" />
            </picture>
          </a>
        </Tilt>
      )}
      {project.code && (
        <Tilt className="tilt-handler" glareEnable>
          <div className="project-code">
            <iframe
              title={project.title}
              srcDoc={project.code}
              referrerPolicy="no-referrer"
              loading="lazy"
              sandbox="allow-same-origin allow-scripts"
            />
          </div>
        </Tilt>
      )}
    </StyledProjectItem>
  )
}

export default ProjectItem
