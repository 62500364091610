import styled from 'styled-components'

const StyledCallout = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  user-select: none;

  a {
    text-decoration: none;

    .callout {
      padding: 20px;
      border-radius: 6px;
      margin: 20px 0 0 0;
      display: grid;
      grid-gap: 6px;
      transition: 0.2s;
      background-color: ${({ theme }) => theme.color.background};
      box-shadow: 0 1px 1px rgba(0,0,0,0.02), 
        0 2px 2px rgba(0,0,0,0.02), 
        0 4px 4px rgba(0,0,0,0.02), 
        0 8px 8px rgba(0,0,0,0.02),
        0 16px 16px rgba(0,0,0,0.02);
  
      .dall-e-blocks {
        display: flex;
        margin-bottom: 10px;

        div {
          height: 7px;
          width: 7px;

          &:nth-of-type(1n) {
            background-color: #ffff65;
          }

          &:nth-of-type(2n) {
            background-color: #44feff;
          }

          &:nth-of-type(3n) {
            background-color: #51da4b;
          }

          &:nth-of-type(4n) {
            background-color: #fe6e3d;
          }

          &:nth-of-type(5n) {
            background-color: #3c46ff;
          }
        }
      }

      .callout-title {
        font-size: 18px;
        font-family: ${({ theme }) => theme.type.oli};
        color: ${({ theme }) => theme.color.gray[700]};
      }
  
      .callout-details {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${({ theme }) => theme.color.gray[500]};
  
        svg {
          height: 20px;
          margin-left: 4px;
        }
      }

      &:hover {
        box-shadow: none;
        background-color: ${({ theme }) => theme.color.gray[200]};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    a {
      .callout {
        margin: 40px 0 0 0;
      }
    }
  }
`

export default StyledCallout
