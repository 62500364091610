import { FC, MouseEvent } from 'react'
import { ExternalLinkIcon } from '../../assets'
import { StyledHeaderLink } from '.'

type Props = {
  label: string
  link?: string
  reference?: string
  curr?: string
  hideMobile?: boolean
}

const HeaderLink: FC<Props> = ({ label, link, reference, curr, hideMobile }) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const anchor = e.target as HTMLAnchorElement
    const id = anchor.getAttribute('data-link')

    if (!id) {
      return
    }

    const scrollElement = document.getElementById(id)

    if (!scrollElement) {
      return
    }

    const scrollOffset = scrollElement.getBoundingClientRect().top + window.scrollY

    window.scrollTo({ top: scrollOffset - 80, behavior: 'smooth' })
  }

  return (
    <StyledHeaderLink
      className={hideMobile ? 'hide-mobile' : undefined}
      draggable={false}
      {...(reference
        ? {
            href: `/#${reference}`,
            'data-link': reference,
            'data-active': curr === reference,
            onClick: (e) => handleClick(e)
          }
        : {
            href: link,
            target: '_blank'
          })}
    >
      {label}
      {link && <ExternalLinkIcon />}
    </StyledHeaderLink>
  )
}

export default HeaderLink
