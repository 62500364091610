import styled from 'styled-components'

const StyledExperimentHeader = styled.header`
  height: 50px;
  background-color: var(--main-color);
  border-bottom: 1px solid ${({ theme }) => theme.color.background}30;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.elevation.fixed};

  .header {
    max-width: 750px;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    text-decoration: none;

    .header-title {
      color: ${({ theme }) => theme.color.background}e3;
      font-family: ${({ theme }) => theme.type.cascadia};
      font-size: 14px;
      letter-spacing: 0.2rem;
      display: flex;
      align-items: center;

      .avatar {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        margin-right: 12px;
        position: relative;

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
        }
      }
    }

    button {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      border-radius: 6px;
      transition: 0.2s;
      color: ${({ theme }) => theme.color.background};
      background-color: rgba(255, 255, 255, 0.1);

      svg {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        transform: rotate(45deg);
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }
`

export default StyledExperimentHeader
