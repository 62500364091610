import styled, { css, keyframes } from 'styled-components'

type StyledProps = {
  type: 'default' | 'gps' | 'airpods'
}

const gpsInAnim = keyframes`
  0% {
    width: 175px;
  }
  100% {
    width: 290px;
  }
`

const airpodsInAnim = keyframes`
  0% {
    width: 175px;
  }
  100% {
    width: 300px;
  }
`

const blurInRightAnim = keyframes`
  0% {
    filter: blur(4px);
    color: #93e4fe;
    transform: translateY(-50%) rotateY(60deg);
  }
  100% {
    filter: blur(0);
    color: #93e4fe;
    transform: translateY(-50%) rotateY(0deg);
  }
`

const blurInLeftAnim = keyframes`
  0% {
    filter: blur(4px);
    color: #93e4fe;
    transform: translateY(-50%) rotateY(-60deg);
  }
  100% {
    filter: blur(0);
    color: #93e4fe;
    transform: translateY(-50%) rotateY(0deg);
  }
`

const StyledDynamicIslandView = styled.div<StyledProps>`
  --main-color: ${({ theme }) => theme.color.foreground};
  background-color: ${({ theme }) => theme.color.foreground};
  min-height: 100vh;

  .dynamic-island-container {
    padding: 0 20px 20px 20px;
    max-width: 750px;
    margin: 0 auto;
    width: 100%;

    .dynamic-island-sim {
      margin: 20px 0;
      position: relative;
      background: linear-gradient(${({ theme }) => theme.color.gray[900]}, ${({ theme }) => theme.color.foreground});
      border-radius: 10px;
      padding: 40px 40px 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      img {
        user-select: none;
        pointer-events: none;
        width: 100%;
        position: relative;

        &.phone-frame {
          z-index: 2;
        }
      }

      .phone-bg {
        user-select: none;
        pointer-events: none;
        background: linear-gradient(${({ theme }) => theme.color.purple[400]}, ${({ theme }) => theme.color.purple[900]});
        top: 50px;
        left: 50%;
        right: 0;
        bottom: 0;
        border-top-right-radius: 20%;
        border-top-left-radius: 20%;
        transform: translate(-50%);
        height: calc(100% - 50px);
        width: calc(100% - 100px);
        position: absolute;
      }

      .dynamic-island {
        top: 87px;
        left: 50%;
        transform: translate(-50%);
        position: absolute;
        height: 49px;
        transition: 0.2s ease-in-out;
        ${({ type }) => type === 'default' && css`
          width: 175px;
        `}
        ${({ type }) => type === 'gps' && css`
          width: 290px;
        `}
        ${({ type }) => type === 'airpods' && css`
          width: 260px;
        `}
        // width: ${({ type }) => type === 'default' ? '175px' : '230px'};
        border-radius: 50rem;
        background-color: black;

        .airpods-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 14px;
          top: calc(50%);
          font-size: 18px;
          transform: translateY(-50%);
          animation: ${blurInRightAnim} forwards ease-in-out 0.35s;
          animation-delay: 0.05s;

          img {
            height: 22px;
            width: 22px;
          }
        }

        .airpods-charge {
          position: absolute;
          right: 12px;
          display: flex;
          user-select: none;
          top: 50%;
          transform: translateY(-50%);
          color: ${({ theme }) => theme.color.foreground};
          animation: ${blurInLeftAnim} forwards ease-in-out 0.35s;
          animation-delay: 0.05s;
          
          svg {
            width: 26px;
            height: 26px;
            transform: rotate(-90deg);
            
            .circle-container__background {
              fill: none;
              stroke: #074c20;
              stroke-width: 4px;
            }
            
            .circle-container__progress {
              fill: none;
              stroke-linecap: round;
              stroke: #1aed80;
              stroke-dasharray: 95 100;
              stroke-linecap: round;
              stroke-width: 4px;
            }
          }
        }

        .gps-arrow {
          color: ${({ theme }) => theme.color.foreground};
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 12px;
          top: calc(50% - 1px);
          font-size: 18px;
          transform: translateY(-50%);
          animation: ${blurInRightAnim} forwards ease-in-out 0.35s;
          animation-delay: 0.05s;

          svg {
            height: 24px;
          }
        }

        .gps-mile {
          position: absolute;
          right: 16px;
          font-family: "SF Pro Rounded";
          display: flex;
          user-select: none;
          top: 50%;
          font-size: 18px;
          transform: translateY(-50%);
          color: ${({ theme }) => theme.color.foreground};
          animation: ${blurInLeftAnim} forwards ease-in-out 0.35s;
          animation-delay: 0.05s;

          abbr {
            margin-left: 2px;
            font-size: 11px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    .dynamic-island-container {
      padding: 0 20px 40px 20px;
  
      .dynamic-island-sim {
        margin: 40px 0;
        
        img {
          width: 100%;
        }
      }
    }
`

export default StyledDynamicIslandView
