import { FC } from 'react'
import { avatar, avatarAvif } from '../../assets'
import { Canvas } from '..'
import { StyledAbout } from '.'

const About: FC = () => {
  return (
    <StyledAbout id="about-me">
      <div className="about-bio">
        <p>Nice to meet you!</p>
        <p>
          I'm Jamel Hammoud, a Full Stack Software Engineer at{' '}
          <a href="https://www.envoy.com" target="_blank" rel="noopener noreferrer">
            Envoy
          </a>
          , an SF-based tech company. Envoy's mission is to build the workplace of the future.
        </p>
        <p>
          As an engineer on the Spaces Team, I empower workplace administrators around the world to
          efficiently manage their buildings & employees.
        </p>
      </div>
      <div className="about-photo">
        <div className="about-photo-image">
          <Canvas />
          <picture>
            <source srcSet={avatarAvif} type="image/avif" />
            <img src={avatar} alt="Jamel Hammoud" />
          </picture>
        </div>
        <span className="scribble-indicator">
          Vandalize my picture...
          <br />
          Go ahead, do it!
        </span>
      </div>
    </StyledAbout>
  )
}

export default About
