import styled from 'styled-components'

type StyledProps = {
  color: string
}

const StyledExperimentView = styled.div<StyledProps>`
  --main-color: ${({ color }) => color};
  background-color: ${({ theme }) => theme.color.gray[100]};
`

export default StyledExperimentView
