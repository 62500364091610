const Experiments = {
  dalleE: '/experiments/dall-e',
  dreambooth: '/experiments/dreambooth',
  vidBucket: '/experiments/building-a-better-loom',
  circle: '/experiments/building-an-oauth-flow-for-circles-custom-sso',
  dynamicIsland: '/experiments/dynamic-island',
  threads: '/experiments/reverse-engineering-instagram-threads-api'
}

const App = {
  home: '/',
  hire: '/hire',
  experiments: Experiments
}

export const ROUTES = {
  App
}
