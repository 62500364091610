import { FC } from 'react'
import { Helmet } from 'react-helmet'
import {
  About,
  CelebrateButton,
  Contact,
  Divider,
  Footer,
  Header,
  Posts,
  Projects,
  Sizzle,
  ToolsOfTrade
} from '../../components'
import { StyledPortfolioView } from '.'

const PortfolioView: FC = () => {
  return (
    <StyledPortfolioView>
      <Helmet>
        <title>Jamel Hammoud - Portfolio Site</title>
        <meta
          name="description"
          content="Jamel Hammoud is an SF-based software engineer building the workplace's future at Envoy."
        />
        <meta
          name="keywords"
          content="Jamel Hammoud, Jamel, Hammoud, Software Developer, Software Engineer, Envoy, Web Developer, Full Stack Developer, Programmer"
        ></meta>

        <meta property="og:title" content="Jamel Hammoud" />
        <meta
          property="og:description"
          content="Jamel Hammoud is an SF-based software engineer building the workplace's future at Envoy."
        />
        <meta property="og:site_name" content="Jamel Hammoud" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.jamelhammoud.com" />

        <meta property="og:image" content="https://jamelhammoud.com/splash.png" />
        <meta
          property="og:image:alt"
          content="Jamel Hammoud is a CTO who blends creativity with code on the web. He's currently working at Social Curator."
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <Header />
      <Sizzle />
      <div className="page-content">
        <ToolsOfTrade />
        <About />
        <Divider label="WRITING" />
        <Posts />
        <Divider label="EXPERIMENTS" />
        <Projects />
        <Divider label="CONTACT" />
        <Contact />
        <Footer />
      </div>
      <CelebrateButton />
      <div id="confetti" />
    </StyledPortfolioView>
  )
}

export default PortfolioView
