import styled from 'styled-components'

const StyledAbout = styled.section`
  max-width: 750px;
  margin: 0 auto;
  padding: 20px 20px 30px 20px;
  width: 100%;
  display: grid;
  gap: 20px;

  .about-bio {
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02rem;
      color: ${({ theme }) => theme.color.gray[700]};

      a {
        color: ${({ theme }) => theme.color.gray[500]};
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .about-photo {
    user-select: none;
    display: none;

    .about-photo-image {
      padding-top: 100%;
      width: 100%;
      background-color: ${({ theme }) => theme.color.background};
      border-radius: 50%;
      position: relative;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
      position: relative;
      overflow: hidden;

      .canvas {
        z-index: 1;
        border-radius: 50%;
        overflow: hidden;
        right: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;

        canvas {
          border-radius: 50%;
        }
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
      }
    }

    .scribble-indicator {
      font-family: ${({ theme }) => theme.type.scribble};
      color: ${({ theme }) => theme.color.gray[500]};
      font-size: 14px;
      letter-spacing: -2%;
      text-align: center;
      margin-top: 24px;
      display: block;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    grid-template-columns: 400px minmax(0, 1fr);
    column-gap: 78px;
    padding: 40px 20px;

    .about-bio {
      p {
        font-size: 20px;
        line-height: 27px;

        &:not(:first-child) {
          margin-top: 26px;
        }
      }
    }

    .about-photo {
      display: block;
    }
  }
`

export default StyledAbout
