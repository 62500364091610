import { Link } from 'react-router-dom'
import styled from 'styled-components'

type StyledProps = {
  color: string
}

const StyledPostItem = styled(Link)<StyledProps>`
  user-select: none;
  text-decoration: none;
  transition: 0.2s;
  display: flex;
  padding: 12px;
  border-radius: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.color.background};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.01),
    0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01), 0 16px 16px rgba(0, 0, 0, 0.01),
    0 32px 32px rgba(0, 0, 0, 0.01);

  .post-color {
    height: 48px;
    width: 4px;
    background-color: ${({ color }) => color};
    opacity: 0.2;
    margin-right: 10px;
    flex-shrink: 0;
    border-radius: 50rem;
  }

  .post-img {
    height: 48px;
    width: 48px;
    margin-right: 14px;
    position: relative;
    border-radius: 6px;
    flex-shrink: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 1px rgb(0, 0, 0, 0.1);
      z-index: 1;
      border-radius: 6px;
    }
  }

  .post {
    margin: 0;
    display: grid;
    gap: 2px;
    align-content: flex-start;
    place-content: center;

    .post-title {
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      color: ${({ theme }) => theme.color.gray[600]};
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
      transition: color 0.2s;
    }

    .post-preview {
      font-size: 15px;
      color: ${({ theme }) => theme.color.gray[500]};
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-align: left;
      -webkit-box-orient: vertical;
      transition: color 0.2s;
    }

    .post-details {
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: 14px;
      font-family: ${({ theme }) => theme.type.cascadia};
      color: ${({ theme }) => theme.color.gray[500]};

      svg {
        height: 18px;
        position: relative;
        bottom: 1px;
        margin-left: 4px;
      }
    }
  }

  &:hover {
    background-color: ${({ color }) => color}10;

    .post {
      .post-title,
      .post-preview {
        color: ${({ color }) => color};
      }
    }
  }
`

export default StyledPostItem
