import { FC } from 'react'
import { posts } from '../../data'
import { PostItem } from '..'
import { StyledPosts } from '.'

const Posts: FC = () => {
  return (
    <StyledPosts>
      <ul className="post-list">
        {[...posts].reverse().map((post, index) => {
          return <PostItem post={post} key={index} />
        })}
      </ul>
    </StyledPosts>
  )
}

export default Posts
