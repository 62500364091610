import styled from 'styled-components'

const StyledPortfolioView = styled.div`
  .page-content {
    background-color: ${({ theme }) => theme.color.gray[100]};
    min-height: 100vh;
    position: relative;
    z-index: ${({ theme }) => theme.elevation.backdrop};
  }

  #confetti {
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: ${({ theme }) => theme.elevation.override};
    pointer-events: none;
    translateX(-50%);
  }
`

export default StyledPortfolioView
