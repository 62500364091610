import { FC, useState } from 'react'
import {
  ExternalLinkIcon,
  GitHubIcon,
  InstagramIcon,
  LinkedInIcon,
  SmileIcon,
  avatar,
  avatarAvif
} from '../../assets'
import { Game } from '..'
import { StyledFooter } from '.'

const Footer: FC = () => {
  const [showGame, setShowGame] = useState(false)

  return (
    <StyledFooter>
      <div className="footer">
        <div className="footer-top">
          <div className="avatar">
            <picture>
              <source srcSet={avatarAvif} type="image/avif" />
              <img src={avatar} alt="Jamel Hammoud" />
            </picture>
          </div>
          <p className="prompt-desktop">
            {showGame ? (
              <Game />
            ) : (
              <button className="play-btn" onClick={() => setShowGame(true)}>
                <SmileIcon /> PLAY
              </button>
            )}
          </p>
          <div className="footer-socials">
            <a
              href="https://www.instagram.com/jamel.hammoud/"
              rel="noopener noreferrer"
              target="_blank"
              className="footer-social"
            >
              <InstagramIcon />
            </a>
            <a
              href="https://www.linkedin.com/in/jamelhammoud/"
              rel="noopener noreferrer"
              target="_blank"
              className="footer-social"
            >
              <LinkedInIcon />
            </a>
            <a
              href="https://github.com/JamelHammoud"
              rel="noopener noreferrer"
              target="_blank"
              className="footer-social"
            >
              <GitHubIcon />
            </a>
          </div>
        </div>
        <hr />
        <div className="footer-bottom">
          <div className="footer-page-details">
            <span>©{new Date().getFullYear()}</span>
            <span>Jamel Hammoud</span>
          </div>
          <a
            href="https://apps.apple.com/us/app/mamera/id1600575010"
            rel="noopener noreferrer"
            target="_blank"
            className="footer-me-link"
          >
            See my app, Mamera <ExternalLinkIcon />
          </a>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
