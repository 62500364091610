import { FC } from 'react'

type Props = {
  className?: string;
}

const ArrowUpRightIcon: FC<Props> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M18 6L6 18"/>
      <path d="M8 6h10v10"/>
    </svg>
  )
}

export default ArrowUpRightIcon
