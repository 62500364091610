import { FC } from 'react'

const PlayIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M6 4v16"/>
      <path d="M20 12L6 20"/>
      <path d="M20 12L6 4"/>
    </svg>
  )
}

export default PlayIcon
