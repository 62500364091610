export const generations = [
  {
    url: 'https://labs.openai.com/s/EKjbR2dCpeJie85btrKYohXl',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-EKjbR2dCpeJie85btrKYohXl/image.webp',
    prompt:
      'A goat dunking a basketball into a hoop floating in space, wearing an LA Lakers purple and yellow jersey, digital illustration, artstation'
  },
  {
    url: 'https://labs.openai.com/s/37hEwFO7wrwpGDm13N1a6Nme',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-37hEwFO7wrwpGDm13N1a6Nme/image.webp',
    prompt: 'Alien sitting at a desk, floating in space, coding, digital illustration'
  },
  {
    url: 'https://labs.openai.com/s/oM44l3QWSM8RFAPcISwOxu3B',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-oM44l3QWSM8RFAPcISwOxu3B/image.webp',
    prompt:
      'A massive gorilla and a boy hugging under a rainbow, bright blue daytime sky, digital illustration, artstation'
  },
  {
    url: 'https://labs.openai.com/s/mrjBUhkOv3F4hIuB6GqzEp8j',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-mrjBUhkOv3F4hIuB6GqzEp8j/image.webp',
    prompt:
      'a group of robots singing happy birthday to a man in a Hawaiian t-shirt sitting on a chair with a cake in front of him, digital art, illustration, high res'
  },
  {
    url: 'https://labs.openai.com/s/g63MCESOF0wQNkx6qqdV99Zb',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-g63MCESOF0wQNkx6qqdV99Zb/image.webp',
    prompt:
      '3D render of a humanoid cartoon banana character facepalming, in a plain and bright yellow room, realistic, high res'
  },
  {
    url: 'https://labs.openai.com/s/C1u2UBSyJ0Pz1vr4bQXPYHps',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-C1u2UBSyJ0Pz1vr4bQXPYHps/image.webp',
    prompt:
      'An illustration of a cat flying in the sky during sunset, pink clouds, digital art, artstation, high res'
  },
  {
    url: 'https://labs.openai.com/s/tNH4fCU9kBAclmftlxarx0L2',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-tNH4fCU9kBAclmftlxarx0L2/image.webp',
    prompt: 'An illustration of a basketball player dunking in outer space, digital art, artstation'
  },
  {
    url: 'https://labs.openai.com/s/W3YWDOTOUZ3VpElF9J8DAKNp',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-W3YWDOTOUZ3VpElF9J8DAKNp/image.webp',
    prompt:
      'A silver Honda Civic car with a black hood in the front, floating in outer space, blue lighting, reflections, artstation, high res, digital art'
  },
  {
    url: 'https://labs.openai.com/s/WWGbYVzRBkhQHoaQpzAsmaUU',
    image:
      'https://openai-labs-public-images-prod.azureedge.net/user-U1dCdMChILlSP6uK92WLo6AG/generations/generation-WWGbYVzRBkhQHoaQpzAsmaUU/image.webp',
    prompt:
      '3D render of a humanoid smiling cartoon banana character in a plain and bright yellow room'
  }
]
