import styled from 'styled-components'

const StyledProjects = styled.section`
  max-width: 750px;
  margin: 0 auto;
  padding: 30px 20px;
  width: 100%;

  .project-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-gap: 30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    padding: 40px 20px;

    .project-list {
      grid-gap: 42px;
    }
  }
`

export default StyledProjects
