import styled from 'styled-components'

const StyledExperimentSplash = styled.div`
  min-height: 275px;
  display: grid;
  align-content: center;
  background-color: var(--main-color);

  .splash {
    padding: 40px 20px;
    max-width: 750px;
    margin: 0 auto;
    width: 100%;

    h1 {
      font-size: 28px;
      font-weight: 400;
      font-family: ${({ theme }) => theme.type.garnett};
      color: ${({ theme }) => theme.color.background};
      line-height: 34px;
      letter-spacing: -0.02rem;

      br {
        display: none;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.m}px) {
    min-height: 375px;

    .splash {
      h1 {
        font-size: 38px;
        line-height: 44px;

        br {
          display: unset;
        }
      }
    }
  }
`

export default StyledExperimentSplash
